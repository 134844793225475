import * as React from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { green, orange, purple } from "@mui/material/colors";

//Global styles

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: "#b19",
      main: "#4B3D3A",
      dark: "#CA9765",
      contrastText: "#fff",
    },
    secondary: {
      light: "#b1b",
      main: "#4B3D3A",
      dark: "#CA9765",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: "800",
      fontSize: "35px",
      fontFamily: "RalewayBold",
      fontStyle: "normal",
      lineHeight: "1",
      letterSpacing: "-0.02em",
    },
    h2: {
      // For Sale and Explore Headers Text
      color: "white",
      fontWeight: "800",
      fontSize: "35px",
      fontFamily: "RalewayBold",
      fontStyle: "normal",
      lineHeight: "1",
      letterSpacing: "-0.02em",
    },
    h3: {
      fontFamily: "RalewaySemiBold",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "20px",
      letterSpacing: "-0.02em",
      marginTop: "0",
      textAlign: "left" as "left",
    },
    h6: {
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      marginTop: "0",
      letterSpacing: "-0.01em",
      textAlign: "left" as "left",
    },
    body1: {
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      letterSpacing: "0.01em",
    },
    body2: {
      //Secondary Text
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      letterSpacing: "0.01em",
      color: "#4B3D3A", //Use this color to specidy the color of secodary text (USDC price etc)
    },
    subtitle2: {
      //Landing page description
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "0.01em",
    },
    subtitle1: {
      //NavBar Text
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      marginTop: "0",
      letterSpacing: "-0.01em",
      textAlign: "left" as "left",
    },
  },
  components: {
    //AppBar
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#e0e0e0",
          position: "fixed", //'absolute' 'fixed' 'relative' 'static' 'sticky',
        },
      },
    },
    //Buttons
    MuiButton: {
      variants: [
        {
          // This is the styling PrimaryButtons
          props: { className: "primary" },
          style: {
            height: "45px",
            borderRadius: "8px",
          },
        },
        {
          // This is the styling SecondaryButtons
          props: { className: "secondary" },
          style: {
            height: "45px",
            borderRadius: "8px",
            margin: "2px",
          },
        },
        {
          // This is the styling tertiaryButtons
          props: { className: "tertiary" },
          style: {
            height: "45px",
            margin: "2px",
          },
        },
        {
          // This is the styling of the Desktop navigation bar items
          props: { className: "navigation" },
          style: {
            height: "45px",
            margin: "2px",
            color: "#4B3D3A",
          },
        },
        {
          // This is the styling of login and signup buttons
          props: { className: "auth" },
          style: {
            height: "45px",
            borderRadius: "10px",
            margin: "5px",
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              backgroundColor: "#fff6e8",
              color: "black",
            },
          },
        },
      ],
    },
    //Accordians
    MuiAccordion: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            borderRadius: "8px",
          },
        },
      ],
    },
    //Dialog PopUps (login /sign up etc)
    MuiDialog: {
      styleOverrides: {
        paper: {
          // backgroundColor: 'blue',
          borderRadius: "16px",
        },
      },
    },
    //CardItem
    MuiCard: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            // background: 'grey'
          },
        },
      ],
    },
    //used for footer components and various background Paper coloring
    MuiPaper: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            background: "#F5F3F2",
          },
        },
        {
          props: { className: "secondary" },
          style: {
            background: "#CA9765",
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            // background: 'grey'
          },
        },
      ],
    },
    //Color of Icons
    MuiIconButton: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            // color: 'black'
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            // background: 'white',
            borderRadius: "8px",
          },
        },
      ],
    },
  },
});

export default theme;

// overides:  TODO
export const globalOverrides = {
  landingPage: {
    text: {
      color: "",
    },
  },
  accordian: {
    text: {
      color: "",
    },
  },
  primaryButton: {
    text: {
      color: "",
    },
  },
  secondaryButton: {
    text: {
      color: "",
    },
  },
  footer: {
    text: {
      color: "",
    },
    icons: {
      color: "",
    },
  },
  socialMedia: {
    color: "",
  },
  navbar: {
    items: {
      color: "",
    },
    mobileDrawer: {
      background: "", //recommended to keep same color as navbar
    },
  },
  cardItem: {
    text: {
      color: "",
    },
  },
  profile: {
    dashboard: {
      text: {
        color: "",
      },
    },
    menuItems: {
      text: {
        color: "",
      },
      navActive: {
        background: "",
      },
    },
  },
  chipItem: {
    chipStyle: {
      // background: 'rgba(255, 255, 255, 0.1)',
      color: "",
    },
  },
  dialogs: {
    text: {
      color: "",
    },
  },
};
